<template>
  <TTView>
    <VRow>
      <VCol>
        Типографика
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <VSimpleTable>
          <thead>
            <tr>
              <th>
                <h2>Шрифт</h2>
              </th>
              <th>
                <h2>CSS класс</h2>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(t,i) in typography"
              :key="i"
            >
              <td>
                <span
                  :class="`tt-text-${t}`"
                  class="text-capitalize"
                >{{ t | clearText }}</span>
              </td>
              <td><h3>{{ `tt-text-${t}` }}</h3></td>
            </tr>
          </tbody>
        </VSimpleTable>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
export default {
  name: 'Typography',
  filters: {
    clearText(t) {
      return t.replace('-', ' ');
    },
  },
  data() {
    return {
      typography: [
        'display', 'headline-1', 'headline-2', 'title-1',
        'title-2', 'subtitle', 'body-1', 'body-2',
        'caption', 'footnote', 'button-1', 'button-2', 'button-3',
      ],
    };
  },
};
</script>

<style lang="scss">
</style>
